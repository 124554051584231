import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Seo from "../components/seo"
import Layout from "../components/layout"

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  > div {
    padding: 20px;
  }

  img {
    max-width: 70%;
  }

  p.details {
    margin: 5px;
    font-size: 24px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    @media screen and (max-width: 590px) {
      font-size: 16px;
    }
  }
`

const ContactInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    display: inline-block;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  div.social {
    img {
      margin: 0 10px;
      display: inline;
    }

    img[alt="Email"] {
      height: 15px;
      width: 20px;
    }

    img[alt="Instagram"] {
      height: 15px;
      width: 15px;
    }

    p {
      margin: 0;
      display: inline;
      font-size: 16px;
    }

    @media screen and (max-width: 500px) {
      img,
      p {
        display: block;
        margin: 5px;
        text-align: center;
      }

      img {
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
`

const IndexPage = (): JSX.Element => (
  <Layout>
    <Seo title="Coming Soon" />
    <MainContainer>
      <div>
        <img src="/img/logo.png" alt="Lighthouse Logo" />
        <p className="details">Manufacturing Atelier &amp; Showroom</p>
        <p className="details">115A Harrington Street, Gardens, Cape Town</p>
      </div>
      <ContactInfo>
        <a href="mailto:info@lighthousemfg.co">
          <div className="social" id="email">
            <img src="/img/email-icon.png" alt="Email" />
            <p>info@lighthousemfg.co</p>
          </div>
        </a>
        <Link to="https://www.instagram.com/lighthouse.mfg/">
          <div className="social" id="instagram">
            <img src="/img/ig-icon.png" alt="Instagram" />
            <p>lighthouse.mfg</p>
          </div>
        </Link>
      </ContactInfo>
    </MainContainer>
  </Layout>
)

export default IndexPage
